.wrapper-configuration-popup {
    width: 12.8rem;
    position: fixed;
    z-index: 999;
    background-color: white;
    visibility: hidden;
    font-size: 10px;
    filter:  drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    .popup-option {
        padding: 1px 8px;
        height: 2.6rem;
        display: flex;
        align-items: center;
        font-size: 1rem;
        cursor: pointer;
        &:hover{
            background-color: #dbdbdb;
        }
    }
}
