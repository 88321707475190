/* Default style for links */
/*                         */
a:link:active  { color: #0000CC}
a:link         { color: #3366CC}
a:visited      { color: #9999CC}
a:link:hover, a:visited:hover   { color: #CC0033}

.WebWorks_Paragraph_List_Outer
{
  margin-left: 0pt;
  text-indent: 0pt;
}

.WebWorks_Paragraph_List_Inner
{
  background: transparent none;
  border: 0pt;
  clear: none;
  margin: 0pt;
  padding: 0pt;
  text-indent: 0pt;
}

/* Use this style to control spacing for inline related topics tags */
/*                                                                  */
div.WWHInlineRelatedTopics
{
  margin-top:    8px;
  margin-bottom: 4px;
}

div.WebWorks_Breadcrumbs
{
  color: #999999;
  font-family: sans-serif;
  font-size: 10pt;
}

a.WebWorks_Breadcrumb_Link
{
  text-decoration: none;
}

div.WebWorks_MiniTOC
{
  background-color: #EEEEEE;
  border-color: #000000;
  border-width: 1px;
  border-style: solid;
  padding: 10px;
  font-family: sans-serif;
}

a.WebWorks_MiniTOC_Link
{
  text-decoration: none;
}

div.WebWorks_MiniTOC_Level1
{
  margin-left: 0pt;
}

div.WebWorks_MiniTOC_Level2
{
  margin-left: 12pt;
}

div.WebWorks_MiniTOC_Level3
{
  margin-left: 24pt;
}

div.WebWorks_MiniTOC_Level4
{
  margin-left: 36pt;
}

div.WebWorks_MiniTOC_Level5
{
  margin-left: 48pt;
}

div.WebWorks_MiniTOC_Level6
{
  margin-left: 60pt;
}

div.WebWorks_MiniTOC_Level7
{
  margin-left: 72pt;
}

div.WebWorks_MiniTOC_Level8
{
  margin-left: 84pt;
}

div.WebWorks_MiniTOC_Level9
{
  margin-left: 96pt;
}

div.WebWorks_MiniTOC_Level10
{
  margin-left: 108pt;
}
/* Company Info */
/*              */
td.WebWorks_Company_Logo_Top
{
  text-align: left;
}
td.WebWorks_Company_Name_Top
{
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}
td.WebWorks_Company_Phone_Top
{
  text-align: left;
  font-size: 12px;
}

td.WebWorks_Company_Fax_Top
{
  text-align: left;
  font-size: 12px; 
}

td.WebWorks_Company_Email_Top
{
  text-align: left;
  font-size: 12px;
}

td.WebWorks_Company_Logo_Bottom
{
  text-align: left;
}

td.WebWorks_Company_Name_Bottom
{
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}

td.WebWorks_Company_Phone_Bottom
{
  text-align: left;
  font-size: 12px;
}

td.WebWorks_Company_Fax_Bottom
{
  text-align: left;
  font-size: 12px; 
}

td.WebWorks_Company_Email_Bottom
{
  text-align: left;
  font-size: 12px;
}