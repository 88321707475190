$primary-theme-text: #1f1f1f;
$primary-theme-text-dull: #1a1a1a;
$white: #ffffff;
$black: #000;
$primary-theme-border: #bababa;
$primary-theme-border-dark: #848484;
$primary-theme-darkGrey: #dbdbdb;
$primary-theme-color: #3366ff;
$pagination-link-border-color: #999999;
$primary-theme-background: #dbdbdb;
$button-disabled: #6c757d;

$bright-red: #ff000f;
$black-16: rgba(0, 0, 0, 0.16);
$grey-white: #ebebeb;
$dark-grey: #dbdbdb;
$dark-gray-btn: #9f9f9f;
$brownish-grey: #686868;
$expand-row-bg-color: #f5f5f5;
$menu-item-border: #0f0f0f;
$dropdown-label-color: #696969;

//left navigation
$sidebar-shadow-color: rgba(0, 0, 0, 0.16);

//search-bar
$left-searchbar-border-color: #9f9f9f;

//notification
$success-alert-notification-background: #0ca919;
$error-alert-notification-background: #f03040;

//warning modal
$alert-icon-color: #ff7300;

$toggle-button-border-unactive: #b6b6b6;

//resultStatus border Color
$error-msg-border-color: #f03040;
$warning-msg-border-color: #ff7300;
