$top-navigation-height: 42px;
$tab-navigation-height: 32px;

//LeftNavigation
$left-navigation-width: 18%;
$left-navigation-icon-height: 48px;
$list-item-left-padding: 5.27rem;

// Topbar
$topbar-height: 80px;

// Sidebar
$sidebar-width: 298px; // 235px;
$sidebar-padding: 5px;
$sidebar-min-width: 50px;

//canvas-container
$canvas-container-width: 1175px;
$canvas-container-height: 524px;

//input-output-tab
$i-o-tab-width: 172px;
$i-o-tab-height: 32px;
