$primary-font-size: 1.4rem;
$primary-font-size-sm: 1.2rem;
$primary-font-size-lg: 1.6rem;

$primary-font-weight: 700;
$secondary-font-weight: 900;
$medium-font-weight: 500;
$least-font-weight: 300;
$medium-font-size: 18px;
$large-font-size: 20px;
$medium-heading-font-size: 16px;
$primary-font-family: Arial;
$small-font-size: 14px;
$micro-font-size: 12px;
$assets-heading-font-weight: bold;
$header-font-weight: bold;

$font-family: ABBvoice, Arial, Helvetica, sans-serif;

$tinySize: 10px;
$smallSize: 12px;
$mediumSize: 14px;
$largeSize: 16px;
$hugeSize: 18px;
$enormousSize: 22px;
$giantSize: 28px;
