div.N1Heading
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 20.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 44.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.N1Heading_outer
{
  margin-bottom: 44.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.N1Heading_inner
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 20.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N1HeadingApp
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 20.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 44.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.N1HeadingApp_outer
{
  margin-bottom: 44.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.N1HeadingApp_inner
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 20.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N1HeadingAppA
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 20.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 44.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.N1HeadingAppA_outer
{
  margin-bottom: 44.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.N1HeadingAppA_inner
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 20.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N1HeadingAppATOC
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 3.0pt;
  margin-left: 70.866pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.N1HeadingAppATOC_outer
{
  margin-bottom: 3.0pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
}

.N1HeadingAppATOC_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N1HeadingTOC
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 3.0pt;
  margin-left: 70.866pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.N1HeadingTOC_outer
{
  margin-bottom: 3.0pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
}

.N1HeadingTOC_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N2Heading
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 16.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 6.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 20.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.N2Heading_outer
{
  margin-bottom: 6.0pt;
  margin-right: 0pt;
  margin-top: 20.0pt;
}

.N2Heading_inner
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 16.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N2HeadingNoTopic
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 16.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 6.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 22.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.N2HeadingNoTopic_outer
{
  margin-bottom: 6.0pt;
  margin-right: 0pt;
  margin-top: 22.0pt;
}

.N2HeadingNoTopic_inner
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 16.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N2HeadingTOC
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 99.2124pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
  text-align: left;
  text-indent: -28.346400000000002pt;
  text-transform: none;
  vertical-align: baseline;
}

.N2HeadingTOC_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
}

.N2HeadingTOC_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N3Heading
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 5.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 15.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.N3Heading_outer
{
  margin-bottom: 5.0pt;
  margin-right: 0pt;
  margin-top: 15.0pt;
}

.N3Heading_inner
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N3HeadingNoTopic
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 5.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 15.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.N3HeadingNoTopic_outer
{
  margin-bottom: 5.0pt;
  margin-right: 0pt;
  margin-top: 15.0pt;
}

.N3HeadingNoTopic_inner
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N3HeadingTOC
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 141.732pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
  text-align: left;
  text-indent: -42.5196pt;
  text-transform: none;
  vertical-align: baseline;
}

.N3HeadingTOC_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
}

.N3HeadingTOC_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N4Heading
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 5pt;
  margin-left: 1pt;
  margin-right: 1pt;
  margin-top: 5pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.N4Heading_outer
{
  margin-bottom: 5pt;
  margin-right: 1pt;
  margin-top: 5pt;
}

.N4Heading_inner
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N4HeadingNoTopic
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 5pt;
  margin-left: 1pt;
  margin-right: 1pt;
  margin-top: 5pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.N4HeadingNoTopic_outer
{
  margin-bottom: 5pt;
  margin-right: 1pt;
  margin-top: 5pt;
}

.N4HeadingNoTopic_inner
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N5Heading
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 5pt;
  margin-left: 1pt;
  margin-right: 1pt;
  margin-top: 5pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.N5Heading_outer
{
  margin-bottom: 5pt;
  margin-right: 1pt;
  margin-top: 5pt;
}

.N5Heading_inner
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N5HeadingNoTopic
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 5pt;
  margin-left: 1pt;
  margin-right: 1pt;
  margin-top: 5pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.N5HeadingNoTopic_outer
{
  margin-bottom: 5pt;
  margin-right: 1pt;
  margin-top: 5pt;
}

.N5HeadingNoTopic_inner
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Anchor
{
  color: #000000;
  font-family: Arial;
  font-size: 2.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 6pt;
  margin-left: 1pt;
  margin-right: 1pt;
  margin-top: 6pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.Anchor_outer
{
  margin-bottom: 6pt;
  margin-right: 1pt;
  margin-top: 6pt;
}

.Anchor_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 2.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Body
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 5pt;
  margin-left: 1pt;
  margin-right: 1pt;
  margin-top: 5pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.Body_outer
{
  margin-bottom: 5pt;
  margin-right: 1pt;
  margin-top: 5pt;
}

.Body_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.BodyBold
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 0.0pt;
  margin-left: 70.866pt;
  margin-right: 0pt;
  margin-top: 6.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.BodyBold_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 6.0pt;
}

.BodyBold_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.BodyIndent
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 5pt;
  margin-left: 1pt;
  margin-right: 1pt;
  margin-top: 5pt;
  text-align: left;
  text-indent: .5cm;
  text-transform: none;
  vertical-align: baseline;
}

.BodyIndent_outer
{
  margin-bottom: 5pt;
  margin-right: 1pt;
  margin-top: 5pt;
}

.BodyIndent_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.BodyIndent2
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 5pt;
  margin-left: 1pt;
  margin-right: 1pt;
  margin-top: 5pt;
  text-align: left;
  text-indent: 1.5cm;
  text-transform: none;
  vertical-align: baseline;
}

.BodyIndent2_outer
{
  margin-bottom: 5pt;
  margin-right: 1pt;
  margin-top: 5pt;
}

.BodyIndent2_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Bullet
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: .20cm;
  margin-left: 0.5cm;
  margin-right: .15cm;
  margin-top: 6pt;
  text-align: left;
  text-indent: -.4cm;
  text-transform: none;
  vertical-align: baseline;
}

.Bullet_outer
{
  margin-bottom: .20cm;
  margin-right: .15cm;
  margin-top: 6pt;
}

.Bullet_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Bullet2
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: .2cm;
  margin-left: 1cm;
  margin-right: .15cm;
  margin-top: 6pt;
  text-align: left;
  text-indent: -.4cm;
  text-transform: none;
  vertical-align: baseline;
}

.Bullet2_outer
{
  margin-bottom: .2cm;
  margin-right: .15cm;
  margin-top: 6pt;
}

.Bullet2_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Bullet2NoSpace
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 1cm;
  margin-right: .15cm;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: -.4cm;
  text-transform: none;
  vertical-align: baseline;
}

.Bullet2NoSpace_outer
{
  margin-bottom: 0.0pt;
  margin-right: .15cm;
  margin-top: 0.0pt;
}

.Bullet2NoSpace_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.BulletNoSpace
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 1cm;
  margin-right: .15cm;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: -.4cm;
  text-transform: none;
  vertical-align: baseline;
}

.BulletNoSpace_outer
{
  margin-bottom: 0.0pt;
  margin-right: .15cm;
  margin-top: 0.0pt;
}

.BulletNoSpace_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.CautionSymbol
{
  color: #000000;
  font-family: Arial;
  font-size: 2.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  padding-bottom: .2cm;
  padding-left: .2cm;
  padding-right: .2cm;
  padding-top: .2cm;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: middle;
}

.CautionSymbol_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  padding-bottom: .2cm;
  padding-left: .2cm;
  padding-right: .2cm;
  padding-top: .2cm;
}

.CautionSymbol_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 2.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: middle;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.CautionText
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 6.0pt;
  padding-bottom: .2cm;
  padding-left: .2cm;
  padding-right: .2cm;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.CautionText_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 6.0pt;
  padding-bottom: .2cm;
  padding-left: .2cm;
  padding-right: .2cm;
}

.CautionText_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.CFA_Name
{
  color: #050505;
  font-family: Arial;
  font-size: 16.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.CFA_Name_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.CFA_Name_inner
{
  color: #050505;
  font-family: Arial;
  font-size: 16.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Copyright
{
  color: #000000;
  font-family: Arial;
  font-size: 9.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 6.0pt;
  margin-left: 70.866pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.Copyright_outer
{
  margin-bottom: 6.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.Copyright_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 9.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.DocTitle
{
  color: #050505;
  font-family: Arial;
  font-size: 16.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 2.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.DocTitle_outer
{
  margin-bottom: 2.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.DocTitle_inner
{
  color: #050505;
  font-family: Arial;
  font-size: 16.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Draft
{
  color: #fed8d8;
  font-family: Arial;
  font-size: 72.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 57.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 57.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.Draft_outer
{
  margin-bottom: 57.0pt;
  margin-right: 0pt;
  margin-top: 57.0pt;
}

.Draft_inner
{
  color: #fed8d8;
  font-family: Arial;
  font-size: 72.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.ELWarningSymbol
{
  color: #000000;
  font-family: Arial;
  font-size: 2.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  padding-bottom: .2cm;
  padding-left: .2cm;
  padding-right: .2cm;
  padding-top: .2cm;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: middle;
}

.ELWarningSymbol_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  padding-bottom: .2cm;
  padding-left: .2cm;
  padding-right: .2cm;
  padding-top: .2cm;
}

.ELWarningSymbol_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 2.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: middle;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Equation
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 8.0pt;
  margin-left: 18pt;
  margin-right: 0pt;
  margin-top: 8.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.Equation_outer
{
  margin-bottom: 8.0pt;
  margin-right: 0pt;
  margin-top: 8.0pt;
}

.Equation_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.FeatureEnd
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 8.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 10.0pt;
  margin-left: 1pt;
  margin-right: 1pt;
  margin-top: 10.0pt;
  text-align: left;
  text-indent: 0cm;
  text-transform: none;
  vertical-align: baseline;
}

.FeatureEnd_outer
{
  margin-bottom: 10.0pt;
  margin-right: 1pt;
  margin-top: 10.0pt;
}

.FeatureEnd_inner
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 8.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.FeatureIcon
{
  color: #000000;
  font-family: Arial;
  font-size: 2.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.FeatureIcon_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.FeatureIcon_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 2.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.FeatureStart
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 8.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 10.0pt;
  margin-left: 1pt;
  margin-right: 1pt;
  margin-top: 10.0pt;
  text-align: left;
  text-indent: 0cm;
  text-transform: none;
  vertical-align: baseline;
}

.FeatureStart_outer
{
  margin-bottom: 10.0pt;
  margin-right: 1pt;
  margin-top: 10.0pt;
}

.FeatureStart_inner
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 8.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.FigureAnchor
{
  color: #000000;
  font-family: Arial;
  font-size: 4.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 70.866pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.FigureAnchor_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.FigureAnchor_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 4.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.FigureTitle
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: italic;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 6pt;
  margin-left: 1pt;
  margin-right: 1pt;
  margin-top: 6pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.FigureTitle_outer
{
  margin-bottom: 6pt;
  margin-right: 1pt;
  margin-top: 6pt;
}

.FigureTitle_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: italic;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.FillNoSpecialFunction
{
  color: #000000;
  font-family: Arial;
  font-size: 2.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.FillNoSpecialFunction_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.FillNoSpecialFunction_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 2.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.FooterL
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.FooterL_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.FooterL_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.FooterR
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.FooterR_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.FooterR_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Footnote
{
  color: #000000;
  font-family: Arial;
  font-size: 8.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: .5cm;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: -.3cm;
  text-transform: none;
  vertical-align: baseline;
}

.Footnote_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.Footnote_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 8.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.FPTableFootnote
{
  color: #0000ff;
  font-family: Arial;
  font-size: 8.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 17.00784pt;
  margin-right: 14.1732pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: -14.173200000000001pt;
  text-transform: none;
  vertical-align: baseline;
}

.FPTableFootnote_outer
{
  margin-bottom: 0.0pt;
  margin-right: 14.1732pt;
  margin-top: 0.0pt;
}

.FPTableFootnote_inner
{
  color: #0000ff;
  font-family: Arial;
  font-size: 8.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.GraphicNo
{
  color: #000000;
  font-family: Arial;
  font-size: 6.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 4.0pt;
  margin-left: 0pt;
  margin-right: 7.2pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.GraphicNo_outer
{
  margin-bottom: 4.0pt;
  margin-right: 7.2pt;
  margin-top: 0.0pt;
}

.GraphicNo_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 6.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.GraphicText
{
  color: #000000;
  font-family: Arial;
  font-size: 9.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 4.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.GraphicText_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 4.0pt;
}

.GraphicText_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 9.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Hang
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 3pt;
  margin-left: 1pt;
  margin-right: 1pt;
  margin-top: 6pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.Hang_outer
{
  margin-bottom: 3pt;
  margin-right: 1pt;
  margin-top: 6pt;
}

.Hang_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Hang_2lines
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 141.732pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: -70.866pt;
  text-transform: none;
  vertical-align: baseline;
}

.Hang_2lines_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.Hang_2lines_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Header2L
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.Header2L_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.Header2L_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Header2R
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.Header2R_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.Header2R_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Headline1
{
  color: #26b2ff;
  font-family: Arial;
  font-size: 24.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.Headline1_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.Headline1_inner
{
  color: #26b2ff;
  font-family: Arial;
  font-size: 24.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Headline2
{
  color: #8cccf2;
  font-family: Arial;
  font-size: 18.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.Headline2_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.Headline2_inner
{
  color: #8cccf2;
  font-family: Arial;
  font-size: 18.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Headline3
{
  color: #8cccf2;
  font-family: Arial;
  font-size: 18.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.Headline3_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.Headline3_inner
{
  color: #8cccf2;
  font-family: Arial;
  font-size: 18.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.INDEX
{
  color: #000000;
  font-family: Arial;
  font-size: 14.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 12.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 6.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.INDEX_outer
{
  margin-bottom: 12.0pt;
  margin-right: 0pt;
  margin-top: 6.0pt;
}

.INDEX_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 14.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.InformationSymbol
{
  color: #000000;
  font-family: Arial;
  font-size: 2.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  padding-bottom: .2cm;
  padding-left: .2cm;
  padding-right: .2cm;
  padding-top: .2cm;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: middle;
}

.InformationSymbol_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  padding-bottom: .2cm;
  padding-left: .2cm;
  padding-right: .2cm;
  padding-top: .2cm;
}

.InformationSymbol_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 2.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: middle;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.InformationText
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 6.0pt;
  padding-bottom: .2cm;
  padding-left: .2cm;
  padding-right: .2cm;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.InformationText_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 6.0pt;
  padding-bottom: .2cm;
  padding-left: .2cm;
  padding-right: .2cm;
}

.InformationText_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Notice
{
  color: #3e586b;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 3.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.Notice_outer
{
  margin-bottom: 3.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.Notice_inner
{
  color: #3e586b;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Parameter
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 170.0784pt;
  margin-right: 0pt;
  margin-top: 6.0pt;
  text-align: left;
  text-indent: -99.21239999999999pt;
  text-transform: none;
  vertical-align: baseline;
}

.Parameter_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 6.0pt;
}

.Parameter_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Parameter_2lines
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 170.0784pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: -99.21239999999999pt;
  text-transform: none;
  vertical-align: baseline;
}

.Parameter_2lines_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.Parameter_2lines_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Parameter_Level2
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 170.0784pt;
  margin-right: 0pt;
  margin-top: 6.0pt;
  text-align: left;
  text-indent: -28.34639999999999pt;
  text-transform: none;
  vertical-align: baseline;
}

.Parameter_Level2_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 6.0pt;
}

.Parameter_Level2_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.ProgramCode
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 5pt;
  margin-left: 1pt;
  margin-right: 1pt;
  margin-top: 5pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.ProgramCode_outer
{
  margin-bottom: 5pt;
  margin-right: 1pt;
  margin-top: 5pt;
}

.ProgramCode_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Slogan
{
  color: #666666;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.Slogan_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.Slogan_inner
{
  color: #666666;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Step1Next
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: .2cm;
  margin-left: .5cm;
  margin-right: .15cm;
  margin-top: 6pt;
  text-align: left;
  text-indent: -.4cm;
  text-transform: none;
  vertical-align: baseline;
}

.Step1Next_outer
{
  margin-bottom: .2cm;
  margin-right: .15cm;
  margin-top: 6pt;
}

.Step1Next_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Step1Start
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: .2cm;
  margin-left: .5cm;
  margin-right: .15cm;
  margin-top: 6pt;
  text-align: left;
  text-indent: -.4cm;
  text-transform: none;
  vertical-align: baseline;
}

.Step1Start_outer
{
  margin-bottom: .2cm;
  margin-right: .15cm;
  margin-top: 6pt;
}

.Step1Start_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.StepANext
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: .2cm;
  margin-left: 1.0cm;
  margin-right: .15cm;
  margin-top: 6pt;
  text-align: left;
  text-indent: -.4cm;
  text-transform: none;
  vertical-align: baseline;
}

.StepANext_outer
{
  margin-bottom: .2cm;
  margin-right: .15cm;
  margin-top: 6pt;
}

.StepANext_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.StepAStart
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: .2cm;
  margin-left: 1.0cm;
  margin-right: .15cm;
  margin-top: 6pt;
  text-align: left;
  text-indent: -.4cm;
  text-transform: none;
  vertical-align: baseline;
}

.StepAStart_outer
{
  margin-bottom: .2cm;
  margin-right: .15cm;
  margin-top: 6pt;
}

.StepAStart_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Suite_Name
{
  color: #050505;
  font-family: Arial;
  font-size: 26.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 4.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.Suite_Name_outer
{
  margin-bottom: 4.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.Suite_Name_inner
{
  color: #050505;
  font-family: Arial;
  font-size: 26.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.TableBody
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.TableBody_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
}

.TableBody_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.TableFootnote
{
  color: #000000;
  font-family: Arial;
  font-size: 8.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: .5cm;
  margin-right: 14.1732pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: -.3cm;
  text-transform: none;
  vertical-align: baseline;
}

.TableFootnote_outer
{
  margin-bottom: 0.0pt;
  margin-right: 14.1732pt;
  margin-top: 0.0pt;
}

.TableFootnote_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 8.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.TableHeadingC
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: Center;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.TableHeadingC_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.TableHeadingC_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: Center;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.TableHeadingL
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: Left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.TableHeadingL_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.TableHeadingL_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: Left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.TABLEOFCONTENTS
{
  color: #000000;
  font-family: Arial;
  font-size: 14.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 12.0pt;
  margin-left: 70.866pt;
  margin-right: 0pt;
  margin-top: 6.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.TABLEOFCONTENTS_outer
{
  margin-bottom: 12.0pt;
  margin-right: 0pt;
  margin-top: 6.0pt;
}

.TABLEOFCONTENTS_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 14.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.TableTextBullet
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 21.26016pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
  text-align: Left;
  text-indent: -21.26016pt;
  text-transform: none;
  vertical-align: baseline;
}

.TableTextBullet_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
}

.TableTextBullet_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: Left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.TableTextC
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
  text-align: Center;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.TableTextC_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
}

.TableTextC_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: Center;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.TableTextGeneral
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.TableTextGeneral_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
}

.TableTextGeneral_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.TableTextIndent
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 21.26016pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
  text-align: Left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.TableTextIndent_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
}

.TableTextIndent_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: Left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.TableTextL
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
  text-align: Left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.TableTextL_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
}

.TableTextL_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: Left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.TableTextR
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
  text-align: Right;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.TableTextR_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
}

.TableTextR_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: Right;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.TableTitle
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: italic;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.TableTitle_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.TableTitle_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: italic;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.THISBOOK
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 20.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 44.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.THISBOOK_outer
{
  margin-bottom: 44.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.THISBOOK_inner
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 20.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.TipSymbol
{
  color: #000000;
  font-family: Arial;
  font-size: 2.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  padding-bottom: .2cm;
  padding-left: .2cm;
  padding-right: .2cm;
  padding-top: .2cm;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: middle;
}

.TipSymbol_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  padding-bottom: .2cm;
  padding-left: .2cm;
  padding-right: .2cm;
  padding-top: .2cm;
}

.TipSymbol_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 2.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: middle;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.TipText
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 6.0pt;
  padding-bottom: .2cm;
  padding-left: .2cm;
  padding-right: .2cm;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.TipText_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 6.0pt;
  padding-bottom: .2cm;
  padding-left: .2cm;
  padding-right: .2cm;
}

.TipText_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Topofpage
{
  color: #000000;
  font-family: Arial;
  font-size: 4.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.Topofpage_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.Topofpage_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 4.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.Version
{
  color: #ffffff;
  font-family: Arial;
  font-size: 9.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.Version_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.Version_inner
{
  color: #ffffff;
  font-family: Arial;
  font-size: 9.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.VersionInside
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.VersionInside_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.VersionInside_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.WarningSymbol
{
  color: #000000;
  font-family: Arial;
  font-size: 2.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  padding-bottom: .2cm;
  padding-left: .2cm;
  padding-right: .2cm;
  padding-top: .2cm;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: middle;
}

.WarningSymbol_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  padding-bottom: .2cm;
  padding-left: .2cm;
  padding-right: .2cm;
  padding-top: .2cm;
}

.WarningSymbol_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 2.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: middle;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.WarningText
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 6.0pt;
  padding-bottom: .2cm;
  padding-left: .2cm;
  padding-right: .2cm;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.WarningText_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 6.0pt;
  padding-bottom: .2cm;
  padding-left: .2cm;
  padding-right: .2cm;
}

.WarningText_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 11.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

span.Bold
{
  font-family: Arial;
  font-weight: bold;
}

span.Courier
{
  font-family: 'Courier New';
  font-size: 10.0pt;
}

span.Displaytext
{
  color: #000000;
  font-family: Arial;
  font-size: 7.0pt;
  font-style: normal;
  font-weight: normal;
  vertical-align: baseline;
}

span.EquationVariables
{
  font-family: Arial;
  font-style: italic;
}

span.Figuretext
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-weight: normal;
  vertical-align: baseline;
}

span.Identity_Number
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-weight: normal;
  vertical-align: baseline;
}

span.Italic
{
  font-family: Arial;
  font-style: italic;
}

span.Plain_Title
{
  color: #000000;
  font-family: Arial;
  font-size: 18.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-transform: none;
  vertical-align: baseline;
}

span.Subscript
{
  font-family: Arial;
  vertical-align: sub;
}

span.Superscript
{
  font-family: Arial;
  vertical-align: super;
}

span.Symbol
{
  font-family: Arial;
}

span.Trademark_Font
{
  font-family: Arial;
  font-weight: normal;
  vertical-align: super;
}

span.Underline
{
  font-family: Arial;
  text-decoration: underline;
}

span.XRefColor
{
  color: DarkBlue;
  font-family: Arial;
}

table.Format_A
{
  border-collapse: collapse;
  margin-bottom: 12.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
  padding-bottom: 4pt;
  padding-left: 6pt;
  padding-right: 6pt;
  padding-top: 6pt;
  text-align: left;
}

table.Format_B
{
  border-collapse: collapse;
  margin-bottom: 12.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
  padding-bottom: 4pt;
  padding-left: 6pt;
  padding-right: 6pt;
  padding-top: 6pt;
  text-align: center;
}

table.Heltabell
{
  border-collapse: collapse;
  margin-bottom: 12.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
  padding-bottom: 4pt;
  padding-left: 4pt;
  padding-right: 4pt;
  padding-top: 6pt;
  text-align: left;
}

table.DubbelHV
{
  border-collapse: collapse;
  margin-bottom: 12.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
  padding-bottom: 4pt;
  padding-left: 4pt;
  padding-right: 4pt;
  padding-top: 6pt;
  text-align: left;
}

table.Normaltabell
{
  border-collapse: collapse;
  margin-bottom: 12.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
  padding-bottom: 4pt;
  padding-left: 4pt;
  padding-right: 4pt;
  padding-top: 6pt;
  text-align: left;
}

table.DubbelH
{
  border-collapse: collapse;
  margin-bottom: 12.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
  padding-bottom: 4pt;
  padding-left: 4pt;
  padding-right: 4pt;
  padding-top: 6pt;
  text-align: left;
}

