div.N1HeadingAppATOC
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 3.0pt;
  margin-left: 70.8661417322835pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.N1HeadingAppATOC_outer
{
  margin-bottom: 3.0pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
}

.N1HeadingAppATOC_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N1HeadingAppTOC
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 3.0pt;
  margin-left: 70.8661417322835pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.N1HeadingAppTOC_outer
{
  margin-bottom: 3.0pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
}

.N1HeadingAppTOC_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N1HeadingTOC
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 3.0pt;
  margin-left: 70.8661417322835pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.N1HeadingTOC_outer
{
  margin-bottom: 3.0pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
}

.N1HeadingTOC_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N2HeadingNoTopicTOC
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 99.2125984251968pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
  text-align: left;
  text-indent: -28.346456692913307pt;
  text-transform: none;
  vertical-align: baseline;
}

.N2HeadingNoTopicTOC_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
}

.N2HeadingNoTopicTOC_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N2HeadingTOC
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 99.2125984251968pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
  text-align: left;
  text-indent: -28.346456692913307pt;
  text-transform: none;
  vertical-align: baseline;
}

.N2HeadingTOC_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
}

.N2HeadingTOC_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N3HeadingNoTopicTOC
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 141.732283464567pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
  text-align: left;
  text-indent: -42.51968503937019pt;
  text-transform: none;
  vertical-align: baseline;
}

.N3HeadingNoTopicTOC_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
}

.N3HeadingNoTopicTOC_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N3HeadingTOC
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 141.732283464567pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
  text-align: left;
  text-indent: -42.51968503937019pt;
  text-transform: none;
  vertical-align: baseline;
}

.N3HeadingTOC_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
}

.N3HeadingTOC_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N4HeadingNoTopicTOC
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 5pt;
  margin-left: 1pt;
  margin-right: 1pt;
  margin-top: 5pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.N4HeadingNoTopicTOC_outer
{
  margin-bottom: 5pt;
  margin-right: 1pt;
  margin-top: 5pt;
}

.N4HeadingNoTopicTOC_inner
{
  color: DarkBlue;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.N4HeadingTOC
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 198.425196850394pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
  text-align: left;
  text-indent: -56.69291338582701pt;
  text-transform: none;
  vertical-align: baseline;
}

.N4HeadingTOC_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 3.0pt;
}

.N4HeadingTOC_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.ActiveTOC
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.ActiveTOC_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.ActiveTOC_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.INDEXTOC
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 1.0pt;
  margin-left: 70.8661417322835pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.INDEXTOC_outer
{
  margin-bottom: 1.0pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
}

.INDEXTOC_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.LFooter
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.LFooter_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.LFooter_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.LHeader2
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.LHeader2_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.LHeader2_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.RevisionHistoryTOC
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 2.0pt;
  margin-left: 70.8661417322835pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.RevisionHistoryTOC_outer
{
  margin-bottom: 2.0pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
}

.RevisionHistoryTOC_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.RFooter
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.RFooter_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.RFooter_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.RHeader2
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  margin-bottom: 0.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.RHeader2_outer
{
  margin-bottom: 0.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.RHeader2_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.TABLEOFCONTENTS
{
  color: #3e586b;
  font-family: Arial;
  font-size: 20.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 44.0pt;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.TABLEOFCONTENTS_outer
{
  margin-bottom: 44.0pt;
  margin-right: 0pt;
  margin-top: 0.0pt;
}

.TABLEOFCONTENTS_inner
{
  color: #3e586b;
  font-family: Arial;
  font-size: 20.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

div.THISBOOKTOC
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  margin-bottom: 3.0pt;
  margin-left: 70.8661417322835pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
  text-align: left;
  text-indent: 0pt;
  text-transform: none;
  vertical-align: baseline;
}

.THISBOOKTOC_outer
{
  margin-bottom: 3.0pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
}

.THISBOOKTOC_inner
{
  color: #000000;
  font-family: Arial;
  font-size: 12.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  text-align: left;
  text-transform: none;
  vertical-align: baseline;
  margin-left: 0pt;
  margin-right: 0pt;
  margin-top: 0pt;
  margin-bottom: 0pt;
  padding-left: 0pt;
  padding-right: 0pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
}

span.Bold
{
  font-family: Arial;
  font-weight: bold;
}

span.Courier
{
  font-family: 'Courier New';
  font-size: 10.0pt;
}

span.Displaytext
{
  color: #000000;
  font-family: Arial;
  font-size: 7.0pt;
  font-style: normal;
  font-weight: normal;
  vertical-align: baseline;
}

span.EquationVariables
{
  font-family: Arial;
  font-style: italic;
}

span.Figuretext
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-weight: normal;
  vertical-align: baseline;
}

span.Identity_Number
{
  color: #000000;
  font-family: Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-weight: normal;
  vertical-align: baseline;
}

span.Italic
{
  font-family: Arial;
  font-style: italic;
}

span.Plain_Title
{
  color: #000000;
  font-family: Arial;
  font-size: 18.0pt;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-transform: none;
  vertical-align: baseline;
}

span.Subscript
{
  font-family: Arial;
  vertical-align: sub;
}

span.Superscript
{
  font-family: Arial;
  vertical-align: super;
}

span.Symbol
{
  font-family: Arial;
}

span.Trademark_Font
{
  font-family: Arial;
  font-weight: normal;
  vertical-align: super;
}

span.Underline
{
  font-family: Arial;
  text-decoration: underline;
}

span.XRefColor
{
  color: DarkBlue;
  font-family: Arial;
}

table.Table
{
  border-collapse: collapse;
  margin-bottom: 10pt;
  margin-left: 1pc;
  margin-right: 1pt;
  margin-top: 10pt;
  padding-bottom: 3pt;
  padding-left: 3pt;
  padding-right: 3pt;
  padding-top: 6pt;
  text-align: left;
}

table.TableWide
{
  border-collapse: collapse;
  margin-bottom: 10pt;
  margin-left: 1pc;
  margin-right: 1pt;
  margin-top: 10pt;
  padding-bottom: 3pt;
  padding-left: 3pt;
  padding-right: 3pt;
  padding-top: 6pt;
  text-align: left;
}

table.Descriptions
{
  border-collapse: collapse;
  margin-bottom: 12.0pt;
  margin-left: 127.55905511811pt;
  margin-right: 0pt;
  margin-top: 12.0pt;
  padding-bottom: 3pt;
  padding-left: 3pt;
  padding-right: 3pt;
  padding-top: 6pt;
  text-align: left;
}

table.FigureWide
{
  border-collapse: collapse;
  margin-bottom: 1pt;
  margin-left: 1pt;
  margin-right: 1pt;
  margin-top: 1pt;
  padding-bottom: 6pt;
  padding-left: 0px;
  padding-right: 0pt;
  padding-top: 0pt;
  text-align: left;
}

table.Figure
{
  border-collapse: collapse;
  margin-bottom: 1pt;
  margin-left: 1pt;
  margin-right: 1pt;
  margin-top: 1pt;
  padding-bottom: 6pt;
  padding-left: 0px;
  padding-right: 0pt;
  padding-top: 0pt;
  text-align: left;
}

