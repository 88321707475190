.modal-dialog {
    max-width: 70vw !important;
}

.modal-dialog.functionConfigCustom.create-model-modal {
    max-width: 96vw !important;
    overflow: hidden;
    height: 95vh;
}

.modal-header {
    .modal-title {
        text-transform: none !important;
    }
    .close {
        opacity: 1 !important;
        &:focus {
            outline: none !important;
        }
    }
}

.modal-dialog.history-config-custom.create-model-modal {
    max-width: 35vw !important;
    overflow: hidden;
}

.modal-dialog.change-password-modal.create-model-modal {
    max-width: 45rem !important;
}
.modal-dialog.delete-library-modal.create-model-modal {
    max-width: 53rem !important;
}
.modal-dialog.password-modal.create-model-modal {
    max-width: 45rem !important;
}

.modal-dialog.add-password-modal.create-model-modal {
    max-width: 60rem !important;
}
