@import "./color.scss";

.redux-toastr {
    .top-right {
        width: 583px !important;
        height: 48px !important;
        z-index: -1 !important;
    }

    .toastr.animated.rrt-success {
        width: 583px !important;
        height: 48px !important;
        background-color: $success-alert-notification-background !important;

        .toastr-icon {
            padding: 2px !important;
            width: 2.2rem !important;
            height: 2.2rem !important;
            fill: $success-alert-notification-background !important;
            border-radius: 50%;
            background-color: $white !important;
        }
    }

    .toastr.animated.rrt-error {
        width: 583px !important;
        height: 48px !important;
        overflow-y: scroll;
        background-color: $error-alert-notification-background !important;

        .toastr-icon {
            float: left;
            padding: 2px !important;
            width: 2.2rem !important;
            height: 2.2rem !important;
            fill: $error-alert-notification-background !important;
            border-radius: 50%;
            background-color: $white !important;
        }
    }

    .close-toastr.toastr-control {
        color: $white !important;
    }
}
